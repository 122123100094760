import {configureStore, combineReducers} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {thunk} from "redux-thunk";

import authReducer from "../pages/Auth/store";
import cateringReducer from "../pages/Home/store";
import productReducer from "../pages/CateringProfile/CaterFoodDetails/store";
import draftOrderReducer from "../pages/Order/Cart/store";
import paymentReducer from "../pages/Order/Checkout/Payment/store";
import addressReducer from "../pages/Modals/Address/store";
import orderInformation from "../pages/Order/OrderInformation/store";
import favoriteReducer from "../pages/Favorite/store";
import corporateReducer from "../pages/Corporate/store";
import employeeReducer from "../pages/Employees/store";
import selectionReducer from "../pages/CateringProfile/CorporateCateringProfile/store";
import profileReducer from "../pages/Profile/store";



const appReducer = combineReducers({
    auth: authReducer,
    catering: cateringReducer,
    product: productReducer,
    draftOrder: draftOrderReducer,
    payment: paymentReducer,
    address: addressReducer,
    order: orderInformation,
    favorite: favoriteReducer,
    corporate: corporateReducer,
    employee: employeeReducer,
    selection: selectionReducer,
    profile: profileReducer
})

const persistConfig = {
    key: 'root',
    keyPrefix: '',
    storage,
    version: 11,
    whitelist: ['auth', 'address', 'draftOrder'],
    migrate: (state) => {
        return new Promise((resolve) => {
            if (state && state._persist.version < 11) {
                const {auth, draftOrder, address, ...rest } = state;
                resolve({
                    ...rest,
                    _persist: {...state._persist, version: 11}
                });
            } else {
                resolve(state)
            }
        })
    }
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const appStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
    }).concat(thunk),
    devTools: process.env.NODE_ENV === 'development',
});


export const persistor = persistStore(appStore);





