import CaterButton from "../../components/CaterButton";
import {apiConfirmMail} from "../../service/AuthService";
import {useState} from "react";
import {useSelector} from "react-redux";

export default function ConfirmAlert() {
    const [status, setStatus] = useState({
        result: false,
        message: ""
    });
    const authState = useSelector((state) => state.auth);

    const onConfirmMail = async () => {
        try {
            const response = await apiConfirmMail({
                mail: authState.data?.user.mail
            });

            if (response.data.result) {
                setStatus({
                    result: true,
                    message: "We have sent you an e-mail to reset your password. Please don't forget to check your spam folder too!"
                })
            } else {
                setStatus({
                    result: false,
                    message: response.data.userMessage
                })
            }
        } catch (e) {
            setStatus({
                result: false,
                message: e.message
            })
        }
    }

    return (
        <div className="flex items-center gap-x-6 bg-red-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
            <div className={"flex items-center justify-center w-full"}>
                <p className="text-sm leading-6 text-white">
                    {status.message === "" && (
                        <>
                            <strong className="font-semibold">Verify</strong>
                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current"
                                 aria-hidden="true">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            Please verify your e-mail address <CaterButton
                            className={"ml-2 text-xs !ring-0 !border-none"} onClick={() => onConfirmMail()}>Send Confirm
                            Mail</CaterButton>
                        </>
                    )}

                    {status.message !== "" && (
                        <>
                            <strong className="font-semibold">Thank you</strong>
                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current"
                                 aria-hidden="true">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            {status.message}
                        </>
                    )}
                </p>
            </div>

        </div>
    )
}