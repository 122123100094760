import {
    IoCartOutline,
    IoHeartOutline,
    IoPersonOutline,
    IoHomeOutline,
    IoTimeOutline
} from 'react-icons/io5';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {setActiveTab, setAuthModal} from "../../pages/Auth/store";
import {setShowCartPanel} from "../../pages/Order/Cart/store";


export const MobileNav = () => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <div className="fixed inset-x-0 bottom-0 bg-gray-50 shadow-lg block sm:hidden">
            <div className="flex justify-around items-center h-16">
                <Link to={"/home"} className="flex flex-col items-center">
                    <IoHomeOutline className="text-xl"/>
                    <span className="text-xs">Home</span>
                </Link>
                <button
                    className="flex flex-col items-center"
                    onClick={() => {
                        if (!authState.logged) {
                            dispatch(setActiveTab("login"));
                            dispatch(setAuthModal(true));
                        } else {
                            navigate("/my-orders")
                        }
                    }}
                >
                    <IoTimeOutline className="text-xl"/>
                    <span className="text-xs">My Order</span>
                </button>
                <button onClick={() => dispatch(setShowCartPanel(true))} className="flex flex-col items-center">
                    <IoCartOutline className="text-xl"/>
                    <span className="text-xs">Cart</span>
                </button>
                <button
                      className="flex flex-col items-center"
                      onClick={() => {
                          if (!authState.logged) {
                              dispatch(setActiveTab("login"));
                              dispatch(setAuthModal(true));
                          } else {
                              navigate("/favorite-restaurants")
                          }
                      }}
                >
                    <IoHeartOutline className="text-xl"/>
                    <span className="text-xs">Favorites</span>
                </button>
                <button className="flex flex-col items-center" onClick={() => {
                    if (!authState.logged) {
                        dispatch(setActiveTab("login"));
                        dispatch(setAuthModal(true));
                    } else {
                        navigate("/profile")
                    }

                }}>
                    <IoPersonOutline className="text-xl"/>
                    <span className="text-xs">{authState.logged ? "Profile" : "Sign In"}</span>
                </button>
            </div>
        </div>
    );
}
