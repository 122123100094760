import ApiService from "./ApiService";

export async function listFavorite(data) {
    return ApiService.fetchData({
        url: '/favorite/list',
        method: 'post',
        data
    })
}

export async function createFavorite(data) {
    return ApiService.fetchData({
        url: '/favorite/create',
        method: 'post',
        data,
    })
}

export async function deleteFavorite(data) {
    return ApiService.fetchData({
        url: '/favorite/delete',
        method: 'post',
        data
    })
}