import React from "react";
import Spinner from "../Spinner";
import classNames from "classnames";
import PropTypes from "prop-types";

const CaterButton = React.forwardRef((props, ref) => {
    const {
        children,
        size,
        color,
        loading,
        disabled,
        active,
        icon,
        className,
        type,
        ...rest
    } = props

    const defaultClass = 'button';
    const buttonOnlyColor = color || "default"
    const buttonSize = 'sm';
    let buttonColor = ""
    // let buttonText = `${buttonOnlyColor === "white" ? "text-black" : "text-white"} text-sm font-medium px-4 py-1.5`

    if (buttonOnlyColor === "default") {

    }

    if (loading || disabled) {
        if (buttonOnlyColor === "default") {
            buttonColor = "rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-not-allowed"
        } else {
            buttonColor = `rounded-md bg-${buttonOnlyColor}-300 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-${buttonOnlyColor}-300 hover:bg-${buttonOnlyColor}-600 cursor-not-allowed`
        }
    } else {
        if (buttonOnlyColor === "default") {
          buttonColor = "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        } else {
          buttonColor = `rounded-md bg-${buttonOnlyColor}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${buttonOnlyColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${buttonOnlyColor}-600`
        }
    }

    const buttonBorder = "border-none"
    const buttonRound = "rounded-md"

    const classes = classNames(
        buttonColor,
        defaultClass,
        buttonSize,
        // buttonText,
        buttonRound,
        buttonBorder,
        className
    )

    const handleClick = (e) => {
        const {onClick} = props
        if (disabled || loading) {
            e.preventDefault()
            return
        }

        onClick?.(e)
    }

    const renderChildren = () => {
        if (loading && children) {
            return (
                <span className="flex items-center justify-center">
                    <Spinner enableTheme={false} className="mr-1" />
                    {children}
                </span>
            )
        }

        if (icon && !children && loading) {
            return <Spinner enableTheme={false} />
        }

        if (icon && !children && !loading) {
            return <>{icon}</>
        }

        if (icon && children && !loading) {
            return (
                <span className="flex items-center justify-center">
                    <span className="text-lg mr-1">{icon}</span>
                    <span className="ltr:ml-1 rtl:mr-1">{children}</span>
                </span>
            )
        }

        return <>{children}</>
    }

    return (
        <button ref={ref} className={classes} {...rest} onClick={handleClick} type={props.type}>
            {renderChildren()}
        </button>
    )
})

CaterButton.propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    active: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

CaterButton.defaultProps = {
    loading: false,
    active: false,
    disabled: false,
    type: "button",
    color: ''
}

export default CaterButton
