import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import Alert from "../../../components/Alert";
import CaterFormError from "../../../components/CaterFormError";
import CaterFormInput from "../../../components/CaterFormInput/CaterFormInput";
import CaterButton from "../../../components/CaterButton";
import * as Yup from "yup";
import {apiForgotPassword, apiResetPassword} from "../../../service/AuthService";
import {setActiveTab} from "../store";

const validationSchema = Yup.object({
    mail: Yup.string().required().email()
})

const ForgotPasswordModal = () => {
    const dispatch = useDispatch();
    const [resetError, setResetError] = useState({
        error: false,
        message: ""
    })
    const [showInfoMessage, setInfoMessage] = useState(false);

    const onResetPassword = async (values) => {
        setResetError({
            error: false,
            message: ""
        })

        try {
            const response = await apiForgotPassword(values);

            if (response.data.result) {
                setInfoMessage(true);
            } else {
                setResetError({
                    error: true,
                    message: response.data.userMessage
                })
            }
        } catch (e) {
            setResetError({
                error: true,
                message: e.message
            })
        }
    }

    return (
        <div>
            {showInfoMessage && (
                <>
                    <Alert type={"success"} className={"mb-3 !font-normal"}>A link has been sent to your email address. You can reset your password by clicking on the link. Please remember to check your spam folder</Alert>
                </>
            )}

            {!showInfoMessage && (
                <>
                    <Formik
                        initialValues={{
                            mail: ""
                        }}
                        onSubmit={(values) => onResetPassword(values)}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            {resetError.error && (
                                <Alert type={"danger"} className={"mb-3 !font-normal"}>{resetError.message}</Alert>
                            )}

                            <div key={"mailDiv"} className={"mb-2"}>
                                <div className={"flex items-center gap-1"}>
                                    <CaterFormError field={"mail"}/>
                                    <label htmlFor={"mail"} className={"mb-1"}>e-Mail</label>
                                </div>
                                <Field
                                    id={"mail"}
                                    name={"mail"}
                                    type={"email"}
                                    required={true}
                                    placeholder={"Your account e-Mail"}
                                    component={CaterFormInput}
                                />

                                <p>Did you remember? <a className={"font-semibold cursor-pointer text-indigo-700"} onClick={() => dispatch(setActiveTab("login"))}>Sign in</a></p>

                                <div className={"flex"}>
                                    <CaterButton color={"indigo"} className={"ml-auto mt-2"} type={"submit"}>Send
                                        Recovery Mail</CaterButton>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </>
            )}

        </div>
    )
}

export default ForgotPasswordModal;