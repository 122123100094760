import {Field, Form, Formik} from "formik";
import {useRef, useState} from "react";
import CaterFormInput from "../../../components/CaterFormInput/CaterFormInput";
import CaterButton from "../../../components/CaterButton";
import * as Yup from "yup";
import CaterFormError from "../../../components/CaterFormError";
import {apiRegister} from "../../../service/AuthService";
import Alert from "../../../components/Alert";
import {BiError} from "react-icons/bi";
import {TbFaceIdError} from "react-icons/tb";
import {setActiveTab} from "../store";
import {useDispatch} from "react-redux";
import Loading from "../../../components/Loading/Loading";
import CaterFormPhoneInput from "../../../components/CaterFormPhoneInput";
import CaterFormCheckbox from "../../../components/CaterFormCheckbox/CaterFormCheckbox";

const validationSchema = Yup.object({
    fullName: Yup.string().required("Your name required for orders"),
    mail: Yup.string().required("Your mail required for orders and login").email(),
    phone: Yup.string().required("Your phone required for orders").min(10).max(10),
    password: Yup.string().required("Your password required for login"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords not match'),
    checkPrivacy: Yup.boolean().isTrue("Please accept the privacy policy and terms."),
    checkSMS: Yup.boolean().isTrue("Please accept the SMS terms."),
})

const RegisterModal = () => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const SubmitRegister = async (values) => {
        try {
            setLoading(true);
            setShowAlert(false);
            setErrorMessage("");
            const response = await apiRegister(values);

            if (response.data.result) {
                dispatch(setActiveTab("login"))
            } else {
                setShowAlert(true)
                setErrorMessage(response.data?.userMessage)
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setShowAlert(true);
            setErrorMessage(e.message)
            setLoading(false);
        }
    }

    return (
        <>
            {showAlert && (
                <Alert type={"danger"} className={"mb-3 !font-normal"}>{errorMessage}</Alert>
            )}

            <Loading loading={loading}>
                <Formik
                    initialValues={{
                        fullName: "",
                        mail: "",
                        password: "",
                        phone: "",
                        type: "standard",
                        checkPrivacy: false,
                        checkSMS: false
                    }}
                    onSubmit={SubmitRegister}
                    innerRef={formikRef}
                    validationSchema={validationSchema}
                >

                    <Form>
                        <div className={"mb-2.5"}>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"fullName"}/>
                                <label htmlFor={"fullName"}>Your Full Name</label>
                            </div>
                            <Field
                                id={"fullName"}
                                name={"fullName"}
                                type={"text"}
                                component={CaterFormInput}
                            />
                        </div>

                        <div className={"mb-2.5"}>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"mail"}/>
                                <label htmlFor={"mail"}>Your e-Mail</label>
                            </div>
                            <Field
                                id={"mail"}
                                name={"mail"}
                                type={"email"}
                                component={CaterFormInput}
                            />
                        </div>

                        <div className={"mb-2.5"}>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"phone"}/>
                                <label htmlFor={"phone"}>Your Phone</label>
                            </div>

                            <Field
                                id={"phone"}
                                name={"phone"}
                                type={"tel"}
                                component={CaterFormPhoneInput}
                            />
                        </div>

                        <div className={"mb-2.5"}>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"password"}/>
                                <label htmlFor={"password"}>Your Password</label>
                            </div>

                            <Field
                                id={"password"}
                                name={"password"}
                                type={"password"}
                                component={CaterFormInput}
                            />
                        </div>

                        <div>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"confirmPassword"}/>
                                <label htmlFor={"confirmPassword"}>Confirm Password</label>
                            </div>
                            <Field
                                id={"confirmPassword"}
                                name={"confirmPassword"}
                                type={"password"}
                                component={CaterFormInput}
                            />
                        </div>

                        <div className={"mt-1"}>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"checkPrivacy"}/>
                                <label htmlFor={"checkPrivacy"} className={"mb-1"}>Terms and Agreements</label>
                            </div>
                            <Field
                                id={"checkPrivacy"}
                                name={"checkPrivacy"}
                                component={CaterFormCheckbox}
                                title={<p>I have read the <a className={"text-blue-600"}
                                                             href={"https://catervalley.com/privacy"} target={"_blank"}>privacy
                                    policy</a> and <a className={"text-blue-600"} href={"https://catervalley.com/terms#:~:text=28.%20SMS,HELP%20to%20help."}
                                                      target={"_blank"}>terms</a>.
                                    I accept it</p>}
                            />
                        </div>

                        <div className={"mt-1"}>
                            <div className={"flex gap-1"}>
                                <CaterFormError field={"checkSMS"}/>
                                <label htmlFor={"checkPrivacy"} className={"mb-1"}>SMS Information</label>
                            </div>
                            <Field
                                id={"checkSMS"}
                                name={"checkSMS"}
                                component={CaterFormCheckbox}
                                title={<p>I only accept transactional SMS. Click here for <a className={"text-blue-600"} href={"https://catervalley.com/terms"}
                                                                                                                                                                     target={"_blank"}>terms</a>.</p>}
                            />
                        </div>

                        <div className={"flex gap-1 items-center mt-4"}>
                            <p>Already have an account?</p><p
                            className={"cursor-pointer font-semibold text-blue-700"}
                            onClick={() => dispatch(setActiveTab("login"))}>Login</p>
                            <CaterButton className={"ml-auto"} color={"indigo"} type={"submit"} loading={loading}>Sign
                                Up</CaterButton>
                        </div>

                    </Form>
                </Formik>
            </Loading>
        </>
    )
}


export default RegisterModal;
