import {ErrorMessage} from "formik";
import {BiError} from "react-icons/bi";
import Tooltip from "../Tooltip";
import {useState} from "react";

const CaterFormError = ({field, ...props}) => {
    const [toolTipOpen, setToolTip] = useState(false);
    return (
            <ErrorMessage name={field} {...props}>
                {msg => (
                    <div>
                        <Tooltip isOpen={toolTipOpen} title={msg}>
                        <p
                            className="text-lg text-red-600 cursor-pointer"
                        >
                            <BiError onClick={() => {
                                setToolTip(!toolTipOpen)
                            }}/>
                        </p>
                        </Tooltip>
                    </div>
                )

                }


            </ErrorMessage>



    )
}

export default CaterFormError