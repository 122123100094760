import {HomeText} from "../text";

export default function Feature({space}) {
    return (
        <div className={"overflow-hidden rounded-xl " + space}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
                <div
                    className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-indigo-600">{HomeText.feature.subTitle}</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{HomeText.feature.title}</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                {HomeText.feature.description}
                            </p>
                             <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {HomeText.feature.sections.map((feature) => (
                                    <div key={feature.title} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                          aria-hidden="true"/>
                                            {feature.title}
                                        </dt>
                                        {' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <img
                        src={HomeText.feature.image}
                        alt="catervalley-feature-image"
                        className="w-[48rem] max-w-none rounded-xl shadow-sm ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                    />
                </div>
            </div>
        </div>
    )
}
