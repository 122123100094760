import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {useDispatch, useSelector} from "react-redux";
import {setShowCartPanel} from "./store";
import {USDollar} from "../../../utils/moneyFormat";
import {IoCartOutline} from "react-icons/io5";
import SummaryItems from "../Checkout/SummaryItems";
import Alert from "../../../components/Alert";
import moment from 'moment';
import auth from "../../Auth";
import CorporateSummaryItems from "../Checkout/CorporateSummaryItems";
moment().format();
moment().locale("en");

export default function Cart() {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const draftOrderState = useSelector((state) => state.draftOrder);
    const addressState = useSelector((state) => state.address);
    const corporateState = useSelector((state) => state.corporate);

    const CloseCartModal = () => {
        dispatch(setShowCartPanel(false))
    }


    return (
        <Transition.Root show={draftOrderState.showCartPanel} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={CloseCartModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">Your
                                                    cart</Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                                        onClick={CloseCartModal}
                                                    >
                                                        <span className="absolute -inset-0.5"/>
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-8">

                                                {authState.logged && authState.data?.user?.isCorporate && (
                                                    <>
                                                        {(Object.keys(corporateState.currentOrder || {}).length <= 0) && (
                                                            <div className="flex mt-72 items-center justify-center">
                                                                <div className="p-6 text-center w-1/2">
                                                                    <IoCartOutline size={60}
                                                                                   className={"text-gray-700 text-center w-full"}/>
                                                                    <p className={"text-gray-700 text-center w-full"}>Your
                                                                        cart empty</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}



                                                {((!draftOrderState.draftOrder?.draftOrderItems || draftOrderState.draftOrder?.draftOrderItems?.length <= 0) && (!authState.data?.user?.isCorporate)) && (
                                                    <div className="flex mt-72 items-center justify-center">
                                                        <div className="p-6 text-center w-1/2">
                                                            <IoCartOutline size={60}
                                                                           className={"text-gray-700 text-center w-full"}/>
                                                            <p className={"text-gray-700 text-center w-full"}>Your
                                                                cart empty</p>
                                                        </div>
                                                    </div>

                                                )}


                                                <div className="flow-root">
                                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                        {authState.logged && authState.data?.user?.isCorporate ? (
                                                            <CorporateSummaryItems/>
                                                        ) : (
                                                            <SummaryItems/>
                                                        )}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                            {authState.data?.user?.isCorporate ? (
                                                <Alert type={"info"} className={"!font-normal text-xs mb-5"}><p className={"text-normal"}>The cut-off time is set as <p className={"!font-bold text-sm"}>{new Date(corporateState.currentOrder?.cutOffTime).toLocaleString() || "could not be calculated. First, create an order."}</p></p></Alert>
                                            ) : (
                                                <Alert type={"info"} className={"!font-normal text-xs mb-5"}>{((addressState.deliveryTime === "Now" )|| (moment(addressState.deliveryTime).toISOString() <= moment(new Date()).add(7, 'hours').toISOString())) ? "For on-demand order types, the estimated delivery time is calculated after the order is confirmed." : <p className={"text-normal"}>The cut-off time is set as <p className={"!font-bold text-sm"}>{moment(new Date(addressState.deliveryTime)).add(-6, 'hours').toLocaleString()}</p></p> }</Alert>
                                            )}
                                            <div
                                                className="flex justify-between text-base font-medium text-gray-900">
                                                <p>Subtotal</p>
                                                {authState.data?.user?.isCorporate ? (
                                                    <p>{USDollar.format(corporateState.currentOrder?.subTotal || 0)}</p>
                                                ) : (
                                                    <p>{USDollar.format(draftOrderState.draftOrder?.draftOrderItems?.reduce((sum, item) => sum + item.itemTotalPrice, 0) || 0)}</p>
                                                )}

                                            </div>
                                            {/*<p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>*/}
                                            {!authState.data?.user?.isCorporate && (
                                                <div className="mt-6">
                                                    <a
                                                        href={"/checkout"}
                                                        onClick={CloseCartModal}
                                                        className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"

                                                    >
                                                        Checkout
                                                    </a>

                                                </div>
                                            )}

                                            <div
                                                className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                <p>
                                                    or{' '}
                                                    <button
                                                        type="button"
                                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                                        onClick={CloseCartModal}
                                                    >
                                                        Continue
                                                        <span aria-hidden="true"> &rarr;</span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
