import ApiService from "./ApiService";

export async function apiCreateOrder(data) {
    return ApiService.fetchData({
        url: '/order/create',
        method: 'post',
        data,
    })
}

export async function apiGetOrder(data) {
    return ApiService.fetchData({
        url: '/order/get',
        method: 'post',
        data,
    })
}

export async function apiListOrder() {
    return ApiService.fetchData({
        url: '/order/list',
        method: 'post'
    })
}

export async function apiListPreviousOrder(data) {
    return ApiService.fetchData({
        url: 'corporate/order/list-previous-order',
        method: 'post',
        data
    })
}

export async function apiGetTrackingOrder(data) {
    return ApiService.fetchData({
        url: 'corporate/order/tracking-order',
        method: 'post',
        data
    })
}

export async function apiSaveDraftGuest(data) {
    return ApiService.fetchData({
        url: '/order/save-draft-guest',
        method: 'post',
        data
    })
}


export async function apiSaveDraft(data) {
    return ApiService.fetchData({
        url: '/order/save-draft',
        method: 'post',
        data
    })
}

export async function apiAssociateDraftOrder(data) {
    return ApiService.fetchData({
        url: '/order/associate-draft-order',
        method: 'post',
        data
    })
}

export async function apiGetDraftOrder(data) {
    return ApiService.fetchData({
        url: '/order/get-draft-order',
        method: 'post',
        data
    })
}


export async function apiListEmployeeOrder(data) {
    return ApiService.fetchData({
        url: '/order/list-employee-order',
        method: 'post',
        data
    })
}


export async function apiEmployeeCreate(data) {
    return ApiService.fetchData({
        url: '/corporate/employee/create',
        method: 'post',
        data
    })
}

export async function apiEmployeeSave(data) {
    return ApiService.fetchData({
        url: '/corporate/employee/update',
        method: 'post',
        data
    })
}


export async function apiGetProduct(data) {
    return ApiService.fetchData({
        url: '/order/get-product',
        method: 'post',
        data
    })
}

export async function apiGetOrderEditItem(data) {
    return ApiService.fetchData({
        url: '/order/get-order-item',
        method: 'post',
        data
    })
}

export async function apiGetKaiserOrder(data) {
    return ApiService.fetchData({
        url: '/order/get-kaiser-order',
        method: 'post',
        data
    })
}

export async function apiKaiserUpdateItem(data) {
    return ApiService.fetchData({
        url: '/order/update-kaiser-item',
        method: 'post',
        data
    })
}


export async function apiKaiserDeleteItem(data) {
    return ApiService.fetchData({
        url: '/order/delete-kaiser-item',
        method: 'post',
        data
    })
}



