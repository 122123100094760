import {PatternFormat} from "react-number-format";

export default function CaterFormPhoneInput({form, field, ...props}) {
    const {
        name,
        id,
        className,
        ref
    } = props

    return (
        <PatternFormat
            id={id}
            name={name}
            ref={ref}
            {...props}
            value={field.value}
            onValueChange={(e) => {
                form.setFieldValue(
                    field.name,
                    e.value
                )
            }}
            format={"+1 (###) ### ####"}
            className={`block w-full rounded-md border-0 py-1.5 ${className} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
        />
    )
}