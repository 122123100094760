import React from 'react';

const Vision = () => (
    <div className={"mb-4"}>
        <h2 className="text-xl font-bold text-gray-700 mb-2">Our Vision</h2>
        <p className="text-lg text-gray-700">
            Our vision is to become the most preferred food catering company in the USA, specializing in corporate breakfasts, lunches, and dinners that inspire and unite. Catervalley aims to provide convenient, efficient, and enjoyable dining experiences, connecting customers with diverse local restaurants while promoting sustainability and innovation.
        </p>
    </div>
);

const Commitment = () => (
    <div className={"mb-4"}>
        <h2 className="text-xl font-bold text-gray-700 mb-2">Our Commitment</h2>
        <p className="text-lg text-gray-700">
            Our commitment to excellence, support for local businesses, and dedication to creating memorable dining experiences are at the core of what we do. We offer a tailored approach to meet the evolving needs of our customers, ensuring each meal is not just a dish but a promise to enrich your corporate gatherings.
        </p>
    </div>
);

const Objectives = () => (
    <div className={"mb-4"}>
        <h2 className="text-xl font-bold text-gray-700 mb-2">Objectives</h2>
        <ul className="list-disc list-inside text-lg text-gray-700">
            <li>Convenience: Easy online ordering for a hassle-free experience.</li>
            <li>Efficiency: Streamlined processes for error-free orders and prompt delivery.</li>
            <li>Time-Saving: Quick, straightforward ordering for busy professionals.</li>
            <li>Customization: Personalized orders to meet all dietary preferences.</li>
            <li>Delivery Tracking: Real-time updates for peace of mind.</li>
        </ul>
    </div>
);

const KeySuccessFactors = () => (
    <div className={"mb-4"}>
        <h2 className="text-xl font-bold text-gray-700 mb-2">Key Success Factors</h2>
        <ul className="list-disc list-inside text-lg text-gray-700">
            <li>Unique Positioning: We carve out our niche with health-conscious and sustainable options.</li>
            <li>Technology-Driven: Our cutting-edge ordering and delivery systems enhance customer experiences.</li>
            <li>Quality Focus: We prioritize strong partnerships and quality control with our vendors.</li>
            <li>Market Adaptability: Responsive to trends and feedback, we stay ahead of the curve.</li>
            <li>Strategic Marketing: We engage our audience through targeted digital strategies.</li>
            <li>Customer-Centric: Exceptional service and customized solutions are our hallmarks.</li>
        </ul>
    </div>
);
const About = () => {
    return (
        <div>
            <div className={"py-4"}>
                <img
                    alt={"cover"}
                    src={"https://api.catervalley.com/uploads/RA32F8ANI7Y7SL9D16H4.png"}
                    className={"w-full h-72 object-cover brightness-75 rounded-xl"}
                />

                <div className={"mt-10"}>
                    <Vision/>
                    <Commitment/>
                    <Objectives/>
                    <KeySuccessFactors/>


                </div>
            </div>
            <p>Best Regards,</p>
            <p className={"mb-2"}>CaterValley Team</p>
        </div>
    );
}

export default About;
