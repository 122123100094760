export default function CaterFormInput({form, field, ...props}) {
   const {
       className,
       ref
   } = props

    return (
        <input
            ref={ref}
            {...field}
            {...props}
            className={`block w-full rounded-md border-0 py-1.5 ${className} shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
        />
    )
}