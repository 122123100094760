import React from "react";

export default function CaterFormTextArea({form, field, ...props}) {
    return (
            <textarea
                {...field}
                {...props}
                className="block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
    )
}