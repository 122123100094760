import ApiService from "./ApiService";

export async function corporateWeekly(data) {
    return ApiService.fetchData({
        url: '/corporate/weekly/list',
        method: 'post',
        data
    })
}

export async function corporateCaterList(data) {
    return ApiService.fetchData({
        url: '/corporate/weekly/catering-list',
        method: 'post',
        data
    })
}

export async function corporateCreateProductList(data) {
    return ApiService.fetchData({
        url: '/corporate/weekly/create-product-list',
        method: 'post',
        data
    })
}

export async function corporateGetProductList(data) {
    return ApiService.fetchData({
        url: '/corporate/weekly/get-product-list',
        method: 'post',
        data
    })
}

export async function corporateCaterWeeklyCheck(data) {
    return ApiService.fetchData({
        url: '/corporate/weekly/catering-weekly',
        method: 'post',
        data
    })
}


export async function corporateCreateOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/create',
        method: 'post',
        data
    })
}

export async function corporateUpdateOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/update',
        method: 'post',
        data
    })
}

export async function corporateSmartOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/smart-order',
        method: 'post',
        data
    })
}


export async function corporateListOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/list',
        method: 'post',
        data
    })
}


export async function corporateSummaryListOrder() {
    return ApiService.fetchData({
        url: '/corporate/order/summary-list',
        method: 'post'
    })
}

export async function corporateGetSmartOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/smart-order-summary',
        method: 'post',
        data
    })
}


export async function corporateGetOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/get',
        method: 'post',
        data
    })
}

export async function corporateDelOrder(data) {
    return ApiService.fetchData({
        url: '/corporate/order/del',
        method: 'post',
        data
    })
}

export async function apiSaveProductNote(data) {
    return ApiService.fetchData({
        url: '/corporate/order/save-note',
        method: 'post',
        data
    })
}

export async function apiGetCorporateAddress() {
    return ApiService.fetchData({
        url: '/corporate/address/get-address',
        method: 'post',
    })
}

export async function apiSaveCorporateAddress(data) {
    return ApiService.fetchData({
        url: '/corporate/address/save-address',
        method: 'post',
        data
    })
}

