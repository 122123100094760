import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showModal: false,
    searchInput: false,
    data: {
        title: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        placeId: ""
    },
    suiteNumber: "",
    note: "",
    deliveryTime: "Now",
    changeAddress: false,
    errorMessage: false
}

const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        setModal: (state, action) => {
            state.showModal = action.payload
        },
        setAddress: (state, action) => {
            state.data = action.payload
        },
        setSearchInput: (state, action) => {
            state.searchInput = action.payload
        },
        setSuiteNumber: (state, action) => {
            state.suiteNumber = action.payload
        },
        setNote: (state, action) => {
            state.note = action.payload
        },
        setDeliveryTime: (state, action) => {
            state.deliveryTime = action.payload
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        }
    }
})

export const {
    setModal,
    setAddress,
    setSearchInput,
    setNote,
    setSuiteNumber,
    setDeliveryTime,
    setErrorMessage
} = addressSlice.actions

export default addressSlice.reducer
