import axios from "axios";
import deepParseJson from "../utils/deepJsonParse";
import {logout, setAccessToken} from "../pages/Auth/store";
import {apiRefreshToken} from "./AuthService";
import {appStore} from "../store";

const unAuthCode = [401];


const BaseService = axios.create({
    timeout: 50000,
    baseURL: "https://api.catervalley.com/api/client/"
    // baseURL: "http://localhost:5555/api/client/"
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem('root')
        const persistData = deepParseJson(rawPersistData);
        let accessToken = persistData?.auth?.data?.session?.accessToken;
        const {auth} = appStore.getState();

        if (!accessToken) {
            accessToken = auth?.data?.session?.accessToken
        }

        if (auth.data.session.accessToken !== accessToken) {
            accessToken = auth?.data?.session?.accessToken
        }

        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken
            config.headers["userId"] = auth?.data?.user?.id
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)


BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const {response} = error
        if (response && unAuthCode.includes(response.status) && !originalRequest._retry) {
            originalRequest._retry = true;
            try {

                if (response.data.message === "user_not_found") {
                    appStore.dispatch(logout())
                    return Promise.reject(error)
                }

                const {auth} = appStore.getState();

                const request = await apiRefreshToken({
                    refreshToken: auth.data.session.refreshToken
                });



                if (request.data.result) {
                    appStore.dispatch(setAccessToken(request.data.data.session.accessToken))
                    return BaseService(originalRequest)
                } else {
                    appStore.dispatch(logout())
                    return Promise.reject(error)
                }
            } catch (e) {
                appStore.dispatch(logout())
                return Promise.reject(error)
            }
        }

        return Promise.reject(error)
    }
)

export default BaseService;
