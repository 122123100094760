import {setDeliveryTime} from "../../pages/Modals/Address/store";
import {setHours} from "date-fns";
import {setMinutes} from "date-fns/setMinutes";
import DatePicker from "react-datepicker";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
moment().format();
moment.locale("en");

const DeliveryTimePicker = ({...props}) => {
    const {
        refButton
    } = props;
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());

    return (
        <DatePicker
            className={"h-0 w0 py-0 px-0 flex"}
            selected={startDate}
            onChange={(date) => {
                if (date.getDate() === new Date().getDate() && date.getHours() === new Date().getHours() && date.getMinutes() === new Date().getMinutes()) {
                    setStartDate(date)
                    dispatch(setDeliveryTime("Now"))
                } else if (date < new Date()) {
                    setStartDate(new Date(moment(new Date()).add(1, 'days').hour(10).minute(0).second(0).toLocaleString()))
                    dispatch(setDeliveryTime(moment(new Date()).add(1, 'days').hour(10).minute(0).second(0).toLocaleString()))
                } else {
                    setStartDate(new Date(moment(date).minute(0).second(0).toLocaleString()))
                    dispatch(setDeliveryTime(moment(date).hour(date.getHours()).minute(0).second(0).toLocaleString()))
                }

            }}
            showTimeSelect
            todayButton={"Now"}
            minDate={new Date()}
            minTime={startDate.toLocaleDateString() === new Date().toLocaleDateString() ? setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours()) : undefined}
            maxTime={startDate.toLocaleDateString() === new Date().toLocaleDateString() ? setHours(setMinutes(new Date(), 45), 23) : undefined}
            timeIntervals={60}
            customInput={refButton}
        />
    )
}

export default DeliveryTimePicker;
