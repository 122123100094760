import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiDeliveryFee} from "../../../../../service/PaymentService";
import {toNumber} from "lodash";

export const getDeliveryFee = createAsyncThunk(
    'deliveryFee',
    async (data) => {
        const response = await apiDeliveryFee(data);
        return response.data
    }
)

const initialState = {
    paymentId: "",
    loading: false,
    subTotal: 0,
    tax: 0,
    deliveryFee: 0,
    tip: {
        value: 0,
        percent: 0
    },
    discount: {
        value: 0,
        code: "",
        percent: 0
    },
    total: 0,
    tipModalShow: false
}

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setPaymentId: (state, action) => {
            state.paymentId = action.payload
        },
        setSubTotal: (state, action) => {
            state.subTotal = action.payload
        },
        setTax: (state, action) => {
            state.tax = action.payload
        },
        setTip: (state, action) => {
            state.tip = action.payload
        },
        calculateTotal: (state) => {
            state.total = (state.subTotal || 0) + (state.tax || 0) + (toNumber(state.deliveryFee) || 0) + (state.tip.value || 0)
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setTipModal: (state, action) => {
            state.tipModalShow = action.payload
        },
        resetPaymentState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDeliveryFee.fulfilled, (state, action) => {
                state.loading = false
                state.deliveryFee = action.payload.data.price
            })
            .addCase(getDeliveryFee.pending, (state) => {
                state.loading = true
            })
            .addCase(getDeliveryFee.rejected, (state) => {
                state.loading = false
                state.deliveryFee = 0
            })
    }
})

export const {
    setPaymentId,
    setSubTotal,
    setTax,
    setTip,
    calculateTotal,
    setLoading,
    setTipModal,
    resetPaymentState
} = paymentSlice.actions

export default paymentSlice.reducer