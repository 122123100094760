import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiListEmployee} from "../../../service/AuthService";
import {apiListEmployeeOrder} from "../../../service/OrderService";

const initialState = {
    loading: false,
    data: {},
    employeeOrderLoading: false,
    employeeOrders: [],
    employeeForm: false,
    employeeFormLoading: false,
    employeeSelected: {}
}

export const getListEmployee = createAsyncThunk(
    'getListEmployee',
    async () => {
        const response = await apiListEmployee();
        return response.data
    }
)

export const getListEmployeeOrder = createAsyncThunk(
    'getListEmployeeOrder',
    async (data) => {
        const response = await apiListEmployeeOrder(data);
        return response.data
    }
)


const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployeeForm: (state, action) => {
            state.employeeForm = action.payload

            if (action.payload === false) {
                state.employeeSelected = {}
            }
        },
        setEmployeeSelected: (state, action) => {
            state.employeeSelected = action.payload
        },
        setEmployeeFormLoading: (state, action) => {
            state.employeeFormLoading = action.payload
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(getListEmployee.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
            })
            .addCase(getListEmployee.pending, (state) => {
                state.loading = true
            })
            .addCase(getListEmployee.rejected, (state) => {
                state.loading = false
            })
            .addCase(getListEmployeeOrder.fulfilled, (state, action) => {
                state.employeeOrderLoading = false
                state.employeeOrders = action.payload.data
            })
            .addCase(getListEmployeeOrder.pending, (state) => {
                state.employeeOrderLoading = true
            })
            .addCase(getListEmployeeOrder.rejected, (state) => {
                state.employeeOrderLoading = false
            })
    })
})

export const {
    setEmployeeForm,
    setEmployeeSelected
} = employeeSlice.actions

export default employeeSlice.reducer
