import {useDispatch, useSelector} from "react-redux";
import {useRef} from "react";
import {setAuthModal} from "./store";
import CaterDialog from "../../components/CaterDialog";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import ForgotPasswordModal from "./ForgotPasswordModal";

const Auth = () => {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);

    const ModalCloseEvent = () => {
        dispatch(setAuthModal(false));
    }

    return (
        <>
            <CaterDialog isOpen={authState.showModal} onCloseEvent={ModalCloseEvent}
                         className={"!max-w-xl"}>
                <div>
                    <img
                        src={"/logo.svg"}
                        alt={"catervalley-logo"}
                        className={"w-1/3 h-1/3 mt-5 mb-5 subpixel-antialiased"}
                    />

                    {authState.page?.activeTab === "login" && (
                        <LoginModal/>
                    )}

                    {authState.page?.activeTab === "register" && (
                        <RegisterModal/>
                    )}

                    {authState.page?.activeTab === "forgot-password" && (
                        <ForgotPasswordModal/>
                    )}

                </div>
            </CaterDialog>
        </>
    )
}

export default Auth;