const Claim = () => {
    return (
        <div>
            <div className={"py-4 "}>
                <img
                    alt={"cover"}
                    src={"https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx"}
                    className={"w-full h-72 object-cover brightness-[.5] rounded-xl"}
                />

                <div className={"py-4"}>
                <h1 className={"text-2xl font-bold"}>CaterValley - Claim</h1>
                </div>
            </div>


        </div>
    );
}

export default Claim;