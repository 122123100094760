import {useDispatch} from "react-redux";
import {login, setActiveTab, setAuthModal} from "../store";
import {Field, Form, Formik} from "formik";
import {useRef, useState} from "react";
import CaterFormInput from "../../../components/CaterFormInput/CaterFormInput";
import CaterButton from "../../../components/CaterButton";
import {apiLogin} from "../../../service/AuthService";
import Alert from "../../../components/Alert";
import Loading from "../../../components/Loading/Loading";
import CaterFormCheckbox from "../../../components/CaterFormCheckbox/CaterFormCheckbox";

const LoginModal = () => {
    const dispatch = useDispatch();
    const formikRef = useRef();
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const SubmitLogin = async (values) => {
        try {
            setLoading(true);
            setErrorMessage("");
            setShowAlert(false);

            const response = await apiLogin(values)

            if (response.data.result) {
                dispatch(login(response.data.data));
                dispatch(setAuthModal(false));
            } else {
                setLoading(false);
                setShowAlert(true);
                setErrorMessage(response.data?.userMessage)
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setShowAlert(true)
            setErrorMessage(e.message)
        }
    }

    return (
        <>
            {showAlert && (
                <Alert type={"danger"} className={"mb-3 !font-normal"}>{errorMessage}</Alert>
            )}
            <Loading loading={loading}>
                <Formik
                    initialValues={{
                        mail: "",
                        password: ""
                    }}
                    onSubmit={SubmitLogin}
                    innerRef={formikRef}
                >

                    <Form>
                        <div className={"mb-4"}>
                            <label htmlFor={"mail"}>Your e-Mail</label>
                            <Field
                                id={"mail"}
                                name={"mail"}
                                type={"email"}
                                component={CaterFormInput}
                            />
                        </div>

                        <div>
                            <label htmlFor={"password"}>Your Password</label>
                            <Field
                                id={"password"}
                                name={"password"}
                                type={showPassword ? "text" : "password"}
                                component={CaterFormInput}
                            />
                            <div className={"mt-2"}>
                                <CaterFormCheckbox onChange={(e) => {
                                    setShowPassword(e.target.checked)
                                }} name={"show_password"} checked={showPassword} id={"show_password"} title={"Show Password"}/>
                            </div>

                            <p className={"cursor-pointer mt-2 capitalize"} onClick={() => dispatch(setActiveTab("forgot-password"))}>Forgot your password?</p>
                        </div>

                        <div className={"flex gap-1 items-center mt-4"}>
                            <p>Don't have an account?</p><p
                            className={"cursor-pointer font-semibold text-blue-700"}
                            onClick={() => dispatch(setActiveTab("register"))}>Sign up</p>
                            <CaterButton className={"ml-auto"} color={"indigo"} type={"submit"} loading={loading}>Login</CaterButton>
                        </div>

                    </Form>
                </Formik>
            </Loading>
        </>
    )
}


export default LoginModal;