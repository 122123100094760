export default function CaterFormCheckbox({field, form, checked, ...props}) {
    return (
        <div className="flex h-6 items-center">
            <input
                {...field}
                {...props}
                checked={field ? field.value : checked}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <div className="ml-1 text-sm">
                <label htmlFor={props.id} className="font-medium text-gray-900">
                    {props.title}
                </label>
            </div>
        </div>
    )
}
